import type React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Card, CardHeader, CardContent, CardFooter } from "./ui/card";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "./ui/table";
import { Button } from "./ui/button";
import { Progress } from "./ui/progress";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationLink,
  PaginationNext,
} from "./ui/pagination";
import { InfoIcon, Gauge } from "lucide-react";
import { api } from "@/lib/api";
import {
  CheckCircledIcon,
  ClockIcon,
  CrossCircledIcon,
  LightningBoltIcon,
} from "@radix-ui/react-icons";
import { Skeleton } from "./ui/skeleton";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useDialogContext } from "@/contexts/dialogContext";
import { formatDuration } from "@/utils/formatDuration";
import { fromSun } from "@/utils/convertCurrency";

const ORDERS_PER_PAGE = 10;
const MAX_VISIBLE_PAGES = 5;

export const OrdersTable: React.FC = () => {
  const { connected, address } = useWallet();
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("orders");
  const { openDialog } = useDialogContext();

  const { data, isLoading, error } = useQuery({
    queryKey: ["orders", currentPage, activeTab, address],
    queryFn: async () => {
      const queryParams = {
        page: currentPage,
        perPage: ORDERS_PER_PAGE,
        ...(activeTab === "myOrders" && address
          ? { wallet: address }
          : address
            ? { exclude: address }
            : {}),
      };

      const res = await api.orders.fetchAll.get({
        query: queryParams,
      });
      return res.data;
    },
    refetchInterval: 20000,
  });

  if (isLoading) return <TableSkeleton />;
  if (error) return <div>An error occurred: {(error as Error).message}</div>;

  const orders = data?.data || [];
  const {
    totalOrders,
    // currentPage: serverCurrentPage,
    perPage,
    // hasNextPage,
  } = data?.metadata || {};

  const totalPages = Math.ceil(totalOrders / perPage);

  const generatePageNumbers = () => {
    if (totalPages <= MAX_VISIBLE_PAGES) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    let startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      1,
    );
    let endPage = startPage + MAX_VISIBLE_PAGES - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - MAX_VISIBLE_PAGES + 1, 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
  };

  const pageNumbers = generatePageNumbers();

  return (
    <Card className="flex h-full flex-col md:col-span-2">
      <CardHeader>
        <Tabs
          value={activeTab}
          onValueChange={(value) => {
            setActiveTab(value);
            setCurrentPage(1);
          }}
        >
          <TabsList>
            <TabsTrigger value="orders">Orders</TabsTrigger>
            <TabsTrigger value="myOrders">My Orders</TabsTrigger>
            <TabsTrigger value="myReceipts">My Receipts</TabsTrigger>
          </TabsList>
        </Tabs>
      </CardHeader>
      <CardContent className="flex-grow overflow-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Date</TableHead>
              <TableHead>Resource</TableHead>
              <TableHead>Price</TableHead>
              <TableHead>Payout</TableHead>
              <TableHead>Fulfilled</TableHead>
              {connected && <TableHead>Action</TableHead>}
            </TableRow>
          </TableHeader>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell className="flex items-center space-x-1">
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8 p-0"
                    onClick={() => openDialog("orderDetails", { id: order.id })}
                  >
                    <InfoIcon className="h-4 w-4" />
                  </Button>
                  <div>
                    <div className="font-medium">
                      {new Intl.DateTimeFormat(undefined, {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      }).format(new Date(order.createdAt))}
                    </div>
                    <div className="text-muted-foreground text-sm">
                      {new Intl.DateTimeFormat(undefined, {
                        day: "numeric",
                        month: "short",
                      }).format(new Date(order.createdAt))}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex items-center space-x-1">
                    <span
                      className={
                        order.resourceType === "ENERGY"
                          ? "text-green-500"
                          : "text-blue-500"
                      }
                    >
                      {order.resourceAmount.toLocaleString()}
                    </span>
                    {order.resourceType === "ENERGY" ? (
                      <LightningBoltIcon className="h-4 w-4 text-green-500" />
                    ) : (
                      <Gauge className="h-4 w-4 text-blue-500" />
                    )}
                  </div>
                  <div className="text-muted-foreground text-sm">
                    / {formatDuration(order.duration)}
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    <span className="font-bold">{order.price}</span> sun
                  </div>
                  {/* APY calculation would go here if available */}
                </TableCell>
                <TableCell>
                  <div className="flex items-baseline gap-1">
                    <span className="font-bold">
                      {fromSun(Number(order.payment) * 0.7)}
                    </span>
                    <span className="text-red-500 text-xs">TRX</span>
                  </div>
                  <div className="text-muted-foreground text-sm">
                    {fromSun(order.payment)} TRX
                  </div>
                </TableCell>
                <TableCell>
                  <div className="relative h-8 w-full">
                    <div className="flex items-center justify-start font-medium">
                      {(() => {
                        if (order.freeze.toString() === "0") return "0%";
                        let percentage =
                          (Number(order.frozen) / Number(order.freeze)) * 100;
                        percentage = Math.min(100, percentage);
                        return percentage % 1 === 0
                          ? `${Math.floor(percentage)}%`
                          : `${percentage.toFixed(2)}%`;
                      })()}
                    </div>
                    <Progress
                      value={
                        order.freeze.toString() !== "0"
                          ? Math.min(
                              100,
                              (Number(order.frozen) / Number(order.freeze)) *
                                100,
                            )
                          : 0
                      }
                    />
                  </div>
                </TableCell>
                {/* check connected or order.targetAddress !== address */}
                {connected &&
                order.targetAddress !== address &&
                order.status === "PENDING" ? (
                  <TableCell>
                    <Button
                      variant="outline"
                      className="w-full"
                      onClick={() => openDialog("fillOrder", { id: order.id })}
                    >
                      SELL
                    </Button>
                  </TableCell>
                ) : order.status === "COMPLETED" ? (
                  <TableCell className="flex items-center justify-center">
                    <CheckCircledIcon className="h-5 w-5" />
                  </TableCell>
                ) : (
                  <TableCell className="flex items-center justify-center">
                    <CrossCircledIcon className="h-5 w-5" />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter className="flex-col gap-2">
        {activeTab === "myOrders" && (
          <Card className="w-full p-2 flex items-center justify-center gap-2 text-muted-foreground  ">
            <ClockIcon className="h-4 w-4" />
            Orders will be open to public 10 minutes from creation.
          </Card>
        )}
        <div className="flex w-full flex-col items-start justify-between gap-4 sm:flex-row sm:items-center">
          <div className="text-muted-foreground text-sm">
            Showing <strong>{orders.length}</strong> of{" "}
            <strong>{totalOrders}</strong> orders
          </div>
          {totalPages > 1 && (
            <div className="flex w-full justify-center sm:w-auto">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(1, prev - 1))
                      }
                      // disabled={currentPage === 1}
                    />
                  </PaginationItem>
                  {pageNumbers.map((pageNumber) => (
                    <PaginationItem key={pageNumber}>
                      <PaginationLink
                        onClick={() => setCurrentPage(pageNumber)}
                        isActive={currentPage === pageNumber}
                      >
                        {pageNumber}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem>
                    <PaginationNext
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(totalPages, prev + 1))
                      }
                      // disabled={!hasNextPage}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          )}
        </div>
      </CardFooter>
    </Card>
  );
};

const TableSkeleton: React.FC = () => {
  return (
    <Card className="flex h-full flex-col md:col-span-2">
      <CardHeader>
        <Skeleton className="h-10 w-[300px]" />
      </CardHeader>
      <CardContent className="flex-grow overflow-auto">
        <Table>
          <TableHeader>
            <TableRow>
              {[...Array(5)].map((_, index) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <TableHead key={index}>
                  <Skeleton className="h-6 w-full" />
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {[...Array(ORDERS_PER_PAGE)].map((_, index) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <TableRow key={index}>
                {[...Array(5)].map((_, cellIndex) => (
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  <TableCell key={cellIndex}>
                    <Skeleton className="h-10 w-full" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className="flex w-full justify-between">
          <Skeleton className="h-8 w-[100px]" />
          <Skeleton className="h-8 w-[200px]" />
        </div>
      </CardFooter>
    </Card>
  );
};
