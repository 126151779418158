import type React from "react";
import { useEffect, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import { Gauge, LoaderIcon, ShoppingCart } from "lucide-react";
import { useDialogContext } from "@/contexts/dialogContext";
import { formatDuration } from "@/utils/formatDuration";
import { FormItem } from "./formItem";
import { useMarketData } from "@/hooks/useMarketData";
import { Skeleton } from "./ui/skeleton";
import { tronWeb } from "@/lib/tronWeb";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { api } from "@/lib/api";
import { toast } from "sonner";
import { fromSun } from "@/utils/convertCurrency";
import { env } from "@/env";
import { queryClient } from "@/lib/queryClient";

const RESOURCE_TYPES = ["ENERGY", "BANDWIDTH"] as const;
type ResourceType = (typeof RESOURCE_TYPES)[number];

const formSchema = z.object({
  resourceAmount: z.number().positive(),
  resourceType: z.enum(RESOURCE_TYPES),
  duration: z.number().min(300, "Minimum duration is 5 minutes"),
  price: z.number().positive(),
  trxAddress: z.string().min(1, "TRX address is required"),
  partialFill: z.boolean(),
  multiSig: z.boolean(),
});

type FormValues = z.infer<typeof formSchema>;

interface OrderFormProps {
  data: any;
  walletAddress: string;
  connected?: boolean;
}

const OrderFormContent: React.FC<OrderFormProps> = ({
  data,
  walletAddress,
  connected = false,
}) => {
  const { openDialog } = useDialogContext();
  const [totalPrice, setTotalPrice] = useState(0);
  const { signTransaction, address } = useWallet();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const calculateSuggestedPrice = useCallback(
    (resourceType: ResourceType, duration: number) => {
      const priceData =
        resourceType === "ENERGY"
          ? data.price.energyPrices
          : data.price.bandwidthPrices;
      for (let i = priceData.length - 1; i >= 0; i--) {
        if (duration >= priceData[i].minDuration) {
          return priceData[i].suggestedPrice;
        }
      }
      return priceData[0].suggestedPrice;
    },
    [data.price],
  );

  const getMinimalPrice = useCallback(
    (resourceType: ResourceType, duration: number) => {
      const priceData =
        resourceType === "ENERGY"
          ? data.price.energyPrices
          : data.price.bandwidthPrices;
      for (let i = priceData.length - 1; i >= 0; i--) {
        if (duration >= priceData[i].minDuration) {
          return priceData[i].minPrice;
        }
      }
      return priceData[0].minPrice;
    },
    [data.price],
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      resourceAmount: data.order.suggestedEnergy,
      resourceType: "ENERGY",
      duration: data.order.suggestedDuration,
      price: calculateSuggestedPrice("ENERGY", data.order.suggestedDuration),
      trxAddress: walletAddress || "",

      // settings
      partialFill: true,
      multiSig: false,
    },
  });

  const resourceType = watch("resourceType");
  const resourceAmount = watch("resourceAmount");
  const duration = watch("duration");
  const price = watch("price");

  useEffect(() => {
    if (walletAddress) setValue("trxAddress", walletAddress);
  }, [walletAddress, setValue]);

  useEffect(() => {
    const minAmount =
      resourceType === "ENERGY"
        ? data.order.minEnergy
        : data.order.minBandwidth;
    const minPrice = getMinimalPrice(resourceType, duration);

    if (resourceAmount < minAmount) {
      setError("resourceAmount", {
        type: "manual",
        message: `≥ ${minAmount}`,
      });
    } else {
      clearErrors("resourceAmount");
    }

    if (price < minPrice) {
      setError("price", {
        type: "manual",
        message: `≥ ${minPrice}`,
      });
    } else {
      clearErrors("price");
    }

    const daysRented = Math.ceil(duration / (24 * 3600));
    setTotalPrice(Math.ceil(resourceAmount * price * daysRented));
  }, [
    resourceType,
    resourceAmount,
    duration,
    price,
    setError,
    clearErrors,
    data.order,
    getMinimalPrice,
  ]);

  const handleResourceTypeChange = useCallback(
    (newType: ResourceType) => {
      setValue("resourceType", newType);
      const suggestedPrice = calculateSuggestedPrice(newType, duration);
      setValue("price", suggestedPrice);
      setValue(
        "resourceAmount",
        newType === "ENERGY"
          ? data.order.suggestedEnergy
          : data.order.suggestedBandwidth,
      );
    },
    [setValue, calculateSuggestedPrice, duration, data.order],
  );

  const handleResourceAmountChange = useCallback(
    (amount: number) => {
      setValue("resourceAmount", Number.isNaN(amount) ? 0 : amount);
    },
    [setValue],
  );

  const onSubmit = handleSubmit((values: FormValues) => {
    const minAmount =
      resourceType === "ENERGY"
        ? data.order.minEnergy
        : data.order.minBandwidth;
    const minPrice = getMinimalPrice(resourceType, duration);

    if (
      values.resourceAmount < minAmount ||
      values.price < minPrice ||
      !address
    ) {
      console.error("Invalid form values", values);
      return;
    }

    openDialog(
      "createOrder",
      {
        resource: values.resourceType,
        duration: values.duration,
        price: values.price,
        targetAddress: values.trxAddress,
        resourceAmount: values.resourceAmount,
        partialFill: values.partialFill,
        multiSig: values.multiSig,
        totalPrice,
      },
      async () => {
        setIsSubmitting(true);

        try {
          const tx = await tronWeb.transactionBuilder.sendTrx(
            data.address,
            env.VITE_TEST_ENV === "true"
              ? Math.round(totalPrice / 1e6)
              : totalPrice,
            address,
          );

          const signature = await signTransaction(tx);
          const res = await tronWeb.trx.sendRawTransaction(signature);

          if (res.result) {
            await api.orders.place.post(
              {
                signature: signature.signature,
                txId: res.transaction.txID,
                resourceType: values.resourceType,
                resourceAmount: values.resourceAmount,
                price: values.price,
                duration: values.duration,
                targetAddress: values.trxAddress,
                partialFill: values.partialFill,
                multiSig: values.multiSig,
              },
              {
                headers: {
                  "x-wallet": address,
                },
              },
            );

            toast.success("Order created");
            queryClient.invalidateQueries({ queryKey: ["orders"] });
          } else {
            toast.error("Failed to create order");
          }
          setIsSubmitting(false);
        } catch (error) {
          console.error("Failed to create order", error);
          setIsSubmitting(false);
          toast.error("Failed to create order");
        }
      },
    );
  });

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <FormItem label="Resource Amount">
          <Controller
            name="resourceAmount"
            control={control}
            render={({ field }) => {
              const minAmount =
                resourceType === "ENERGY"
                  ? data.order.minEnergy
                  : data.order.minBandwidth;
              return (
                <Input
                  {...field}
                  type="number"
                  className={resourceAmount < minAmount ? "border-red-500" : ""}
                  step={resourceType === "ENERGY" ? 1000 : 100}
                  placeholder={
                    resourceType === "ENERGY"
                      ? data.order.suggestedEnergy.toString()
                      : data.order.suggestedBandwidth.toString()
                  }
                  onChange={(e) => {
                    handleResourceAmountChange(Number(e.target.value));
                  }}
                />
              );
            }}
          />
          {errors.resourceAmount && (
            <p className="mt-1 text-red-500 text-sm">
              {errors.resourceAmount.message}
            </p>
          )}
        </FormItem>
        <FormItem label="Resource Type">
          <Controller
            name="resourceType"
            control={control}
            render={({ field }) => (
              <Select
                onValueChange={(value: "ENERGY" | "BANDWIDTH") => {
                  handleResourceTypeChange(value);
                }}
                value={field.value}
              >
                <SelectTrigger id="resourceType">
                  <SelectValue placeholder="Select resource" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="ENERGY">Energy</SelectItem>
                  <SelectItem value="BANDWIDTH">Bandwidth</SelectItem>
                </SelectContent>
              </Select>
            )}
          />
        </FormItem>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <FormItem label="Duration" tooltip="Duration in seconds">
          <Controller
            name="duration"
            control={control}
            render={({ field }) => (
              <Select
                onValueChange={(value) => {
                  const newDuration = Number(value);
                  field.onChange(newDuration);
                  const suggestedPrice = calculateSuggestedPrice(
                    resourceType,
                    newDuration,
                  );
                  setValue("price", suggestedPrice);
                }}
                value={field.value.toString()}
              >
                <SelectTrigger id="duration">
                  <SelectValue placeholder="Select duration" />
                </SelectTrigger>
                <SelectContent>
                  {data.order.durations.map((d: number) => (
                    <SelectItem key={d} value={d.toString()}>
                      {formatDuration(d)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </FormItem>
        <FormItem label="Price">
          <Controller
            name="price"
            control={control}
            render={({ field }) => {
              const minPrice = getMinimalPrice(resourceType, duration);
              return (
                <Input
                  {...field}
                  type="number"
                  className={price < minPrice ? "border-red-500" : ""}
                  step={5}
                  placeholder="Enter price"
                  onChange={(e) => {
                    field.onChange(Number(e.target.value));
                  }}
                />
              );
            }}
          />
          {errors.price && (
            <p className="mt-1 text-red-500 text-sm">{errors.price.message}</p>
          )}
        </FormItem>
      </div>

      <FormItem label="TRX Address">
        <Controller
          name="trxAddress"
          control={control}
          render={({ field }) => <Input {...field} placeholder="TRX address" />}
        />
        {errors.trxAddress && (
          <p className="mt-1 text-red-500 text-sm">
            {errors.trxAddress.message}
          </p>
        )}
      </FormItem>

      <div className="flex items-center space-x-2">
        <Controller
          name="partialFill"
          control={control}
          render={({ field }) => (
            <Checkbox
              id="allowPartialFill"
              checked={field.value}
              onCheckedChange={field.onChange}
            />
          )}
        />
        <Label htmlFor="allowPartialFill">Allow Partial Fill</Label>
      </div>

      {/* <div className="flex items-center space-x-2">
        <Controller
          name="multiSig"
          control={control}
          render={({ field }) => (
            <Checkbox
              id="multisignature"
              checked={field.value}
              onCheckedChange={field.onChange}
            />
          )}
        />
        <Label htmlFor="multisignature">Multisignature</Label>
      </div> */}

      <Separator />

      <div className="flex justify-between text-sm">
        <span>{resourceType === "ENERGY" ? "Energy" : "Bandwidth"}</span>
        <span className="flex items-center gap-1 font-semibold text-primary">
          {resourceAmount.toLocaleString()}
          {resourceType === "ENERGY" ? (
            <LightningBoltIcon className="h-4 w-4" />
          ) : (
            <Gauge className="h-4 w-4" />
          )}
          / {formatDuration(duration)}
        </span>
      </div>

      <div className="flex justify-between font-semibold">
        <span>Total</span>
        <span>{fromSun(totalPrice)} TRX</span>
      </div>

      <Button
        disabled={
          !connected ||
          !isValid ||
          !!errors.resourceAmount ||
          !!errors.price ||
          isSubmitting
        }
        className="w-full items-center justify-center gap-2 font-bold"
        type="submit"
      >
        {isSubmitting ? (
          <>
            <LoaderIcon className="h-5 w-5 animate-spin" /> Submitting..
          </>
        ) : (
          <>
            <ShoppingCart className="h-5 w-5" />
            Order
          </>
        )}
      </Button>
    </form>
  );
};

export function OrderForm({
  walletAddress,
  connected = false,
}: {
  walletAddress: string;
  connected: boolean;
}): JSX.Element {
  const { data, isLoading, error } = useMarketData();

  if (isLoading) {
    return (
      <div className="space-y-6">
        <Skeleton className="h-10 w-full" />
        <Skeleton className="h-10 w-full" />
        <Skeleton className="h-10 w-full" />
        <Skeleton className="h-10 w-full" />
        <Skeleton className="h-10 w-full" />
        <Skeleton className="h-6 w-1/2" />
        <Skeleton className="h-6 w-1/2" />
        <Skeleton className="h-10 w-full" />
      </div>
    );
  }

  if (error) {
    return <p>Error loading market data. Please try again later.</p>;
  }

  return data ? (
    <OrderFormContent
      data={data}
      walletAddress={walletAddress}
      connected={connected}
    />
  ) : (
    <p>Failed to load market data</p>
  );
}
