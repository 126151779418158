import { createFileRoute } from "@tanstack/react-router";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from "@/components/ui/card";

import { Separator } from "@/components/ui/separator";
import { useTronAccount } from "@/hooks/useTronWeb";
import { Progress } from "@/components/ui/progress";
import { ConnectButton } from "@/components/connectButton";

import { OrderForm } from "@/components/orderForm";
import { OrdersTable } from "@/components/ordersTable";

export const Route = createFileRoute("/")({
  component: HomeComponent,
});

function HomeComponent() {
  const { wallet, connected } = useWallet();

  const { data: tronAccount, isLoading, isError } = useTronAccount();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error loading account information</div>;
  }

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <Card className="flex h-full flex-col justify-between">
          <div className="h-[30%]">
            {connected && tronAccount ? (
              <>
                <CardHeader>
                  <CardTitle>Wallet</CardTitle>
                  <CardDescription>{wallet?.adapter.address}</CardDescription>
                  <CardDescription className="font-bold text-base text-primary">
                    {tronAccount?.available.toLocaleString()} TRX{" "}
                    <span className="text-muted-foreground text-sm">
                      / {tronAccount?.balance.toLocaleString()} TRX (
                      {tronAccount?.staked.toLocaleString()} TRX staked)
                    </span>
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-6 rounded-md bg-secondary px-4 py-2 text-sm">
                    <div className="flex flex-col gap-1">
                      <div>
                        <p className="font-bold">Energy</p>
                        <p>
                          {(
                            tronAccount?.resource.energy.total -
                            tronAccount.resource.energy.used
                          ).toLocaleString()}{" "}
                          /{" "}
                          {tronAccount?.resource.energy.total.toLocaleString()}
                        </p>
                      </div>
                      <Progress
                        value={
                          ((tronAccount?.resource.energy.total -
                            tronAccount?.resource.energy.used) /
                            tronAccount?.resource.energy.total) *
                          100
                        }
                      />
                      <p className="mt-1 text-muted-foreground text-xs">
                        Delegated:{" "}
                        {tronAccount?.energy_delegated.toLocaleString() || 0}
                      </p>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div>
                        <p className="font-bold">Bandwidth</p>
                        <p>
                          {(
                            tronAccount?.resource.bandwidth.total -
                            tronAccount.resource.bandwidth.used
                          ).toLocaleString()}{" "}
                          /{" "}
                          {tronAccount?.resource.bandwidth.total.toLocaleString()}
                        </p>
                      </div>
                      <Progress
                        value={
                          ((tronAccount?.resource.bandwidth.total -
                            tronAccount?.resource.bandwidth.used) /
                            tronAccount?.resource.bandwidth.total) *
                          100
                        }
                      />
                      <p className="mt-1 text-muted-foreground text-xs">
                        Delegated:{" "}
                        {tronAccount?.bandwidth_delegated.toLocaleString() || 0}
                      </p>
                    </div>
                  </div>
                </CardContent>
              </>
            ) : (
              <div className="relative flex h-full w-full flex-col items-center justify-center">
                <div className="absolute">
                  <div className="h-32 w-64 animate-pulse rounded-full bg-blue-500 opacity-50 blur-3xl filter" />
                </div>
                <ConnectButton className="relative z-10" />
              </div>
            )}
          </div>

          <Separator />

          <div className="overflow-y-auto">
            <CardHeader>
              <CardTitle>Order Form</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <OrderForm
                walletAddress={wallet?.adapter.address || ""}
                connected={connected}
              />
            </CardContent>
          </div>
        </Card>

        <OrdersTable />
      </div>
    </div>
  );
}
