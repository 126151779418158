import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import type { DialogProps } from "@/contexts/dialogContext";
import { api } from "@/lib/api";
import { fromSun } from "@/utils/convertCurrency";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";

export const TransactionsDialog: React.FC<DialogProps<"transactions">> = ({
  isOpen,
  onClose,
  payload,
}) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["transactions", payload.id],
    queryFn: async () => {
      const response = await api.orders.transactions.get({
        query: {
          orderId: payload.id,
        },
      });
      console.log(response.data?.length);
      return response.data;
    },
  });

  if (isLoading) {
    return <div />;
  }
  if (error || !data) {
    toast.error("Error loading transactions");
    return <div />;
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Transactions</DialogTitle>
        </DialogHeader>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Tx</TableHead>
              <TableHead>Address</TableHead>
              <TableHead>Delegated</TableHead>
              <TableHead>Payment</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>No transactions yet.</TableCell>
              </TableRow>
            ) : (
              data.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell className="font-mono">
                    <a
                      className="text-muted-foreground underline"
                      href={`https://tronscan.org/#/transaction/${transaction.id}`}
                    >
                      {transaction.id.substring(0, 10)}...
                    </a>
                  </TableCell>
                  <TableCell className="font-mono">
                    {transaction.originAddress}
                  </TableCell>
                  <TableCell>{fromSun(transaction.frozen)} TRX</TableCell>
                  <TableCell>
                    {(transaction.reward / 1e6).toFixed(4)} TRX
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};
