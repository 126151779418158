// src/contexts/DialogContext.tsx
import type React from "react";
import {
  createContext,
  useContext,
  useState,
  useCallback,
  type ReactNode,
} from "react";
import { OrderDetailsDialog } from "@/components/dialog/orderDetailsDialog";
import { FillOrderDialog } from "@/components/dialog/fillOrderDialog";
import { CreateOrderDialog } from "@/components/dialog/createOrderDialog";
import { TransactionsDialog } from "@/components/dialog/transactionsDialog";

// Define the structure for each dialog's payload
export interface CreateOrderPayload {
  resource: "ENERGY" | "BANDWIDTH";
  duration: number;
  price: number;
  targetAddress: string;
  partialFill: boolean;
  multiSig: boolean;
  resourceAmount: number;
  totalPrice: number;
}

export interface TransactionsPayload {
  id: number;
}

export interface FillOrderPayload {
  id: number;
}

export interface OrderDetailsPayload {
  id: number;
}

// Define a map of all dialog types to their payloads
interface DialogPayloadMap {
  createOrder: CreateOrderPayload;
  fillOrder: FillOrderPayload;
  orderDetails: OrderDetailsPayload;
  transactions: TransactionsPayload;
}

type DialogType = keyof DialogPayloadMap;

type OpenDialogState = {
  [K in DialogType]: {
    type: K;
    isOpen: true;
    payload: DialogPayloadMap[K];
    onConfirm?: () => void;
  };
}[DialogType];

type ClosedDialogState = {
  type: null;
  isOpen: false;
  payload: null;
  onConfirm: null;
};

export type DialogState = OpenDialogState | ClosedDialogState;

interface DialogContextType {
  dialogState: DialogState;
  openDialog: <T extends DialogType>(
    type: T,
    payload: DialogPayloadMap[T],
    onConfirm?: () => void,
  ) => void;
  closeDialog: () => void;
  confirmDialog: () => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const DialogProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [dialogState, setDialogState] = useState<DialogState>({
    type: null,
    isOpen: false,
    payload: null,
    onConfirm: null,
  });

  const openDialog = useCallback(
    <T extends DialogType>(
      type: T,
      payload: DialogPayloadMap[T],
      onConfirm?: () => void,
    ) => {
      setDialogState({ type, isOpen: true, payload, onConfirm } as DialogState);
    },
    [],
  );

  const closeDialog = useCallback(() => {
    setDialogState({
      type: null,
      isOpen: false,
      payload: null,
      onConfirm: null,
    });
  }, []);

  const confirmDialog = useCallback(() => {
    if (dialogState.onConfirm) {
      dialogState.onConfirm();
    }
    closeDialog();
  }, [dialogState, closeDialog]);

  return (
    <DialogContext.Provider
      value={{ dialogState, openDialog, closeDialog, confirmDialog }}
    >
      {children}
      <DialogRenderer
        dialogState={dialogState}
        onClose={closeDialog}
        onConfirm={confirmDialog}
      />
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialogContext must be used within a DialogProvider");
  }
  return context;
};

const DialogRenderer: React.FC<{
  dialogState: DialogState;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ dialogState, onClose, onConfirm }) => {
  switch (dialogState.type) {
    case "orderDetails":
      return (
        <OrderDetailsDialog
          isOpen={dialogState.isOpen}
          onClose={onClose}
          payload={dialogState.payload}
        />
      );
    case "createOrder":
      return (
        <CreateOrderDialog
          isOpen={dialogState.isOpen}
          onClose={onClose}
          onConfirm={onConfirm}
          payload={dialogState.payload}
        />
      );
    case "fillOrder":
      return (
        <FillOrderDialog
          isOpen={dialogState.isOpen}
          onClose={onClose}
          payload={dialogState.payload}
        />
      );
    case "transactions":
      return (
        <TransactionsDialog
          isOpen={dialogState.isOpen}
          onClose={onClose}
          payload={dialogState.payload}
        />
      );
    default:
      return null;
  }
};

// Type guard to check if a dialog is open
export function isDialogOpen<T extends DialogType>(
  state: DialogState,
  type: T,
): state is Extract<DialogState, { type: T; isOpen: true }> {
  return state.type === type && state.isOpen;
}

// Utility type for components that need dialog props
export type DialogProps<T extends DialogType> = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
} & { payload: DialogPayloadMap[T] };
