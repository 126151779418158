import { tronWeb } from "@/lib/tronWeb";
import { fromSun } from "@/utils/convertCurrency";
import {
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from "@tanstack/react-query";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";

export const TRON_ACCOUNT_KEY = "tronAccount";

interface TronAccount {
  balance: number;
  available: number;
  staked: number;
  energy_staked: number;
  energy_delegated: number;
  energy_acquired: number;
  energy_delegatable: number;
  bandwidth_staked: number;
  bandwidth_delegated: number;
  bandwidth_acquired: number;
  bandwidth_delegatable: number;
  resource: {
    energy: {
      conversion_rate: number;
      used: number;
      total: number;
      recovery_time: number;
    };
    bandwidth: {
      conversion_rate: number;
      used: number;
      total: number;
      recovery_time: number;
    };
    tronpower: {
      used: number;
      total: number;
    };
  };
}

interface TronWebResource {
  TotalEnergyWeight: number;
  TotalEnergyLimit: number;
  TotalNetWeight: number;
  TotalNetLimit: number;
  freeNetLimit?: number;
  NetLimit?: number;
  freeNetUsed?: number;
  NetUsed?: number;
  EnergyLimit?: number;
  EnergyUsed?: number;
  tronPowerLimit?: number;
  tronPowerUsed?: number;
}

interface TronWebEnergy {
  max_size?: number;
}

export function useTronAccount(): UseQueryResult<TronAccount, Error> {
  const { connected, address } = useWallet();

  return useQuery<TronAccount, Error>({
    queryKey: [TRON_ACCOUNT_KEY, address],
    queryFn: async () => {
      if (!connected || !address) {
        throw new Error("Wallet not connected");
      }

      const balance = await tronWeb.trx.getAccount(address);
      const resource: TronWebResource =
        await tronWeb.trx.getAccountResources(address);

      const account: TronAccount = {
        balance: 0,
        available: 0,
        staked: 0,
        energy_staked: 0,
        energy_delegated: 0,
        energy_acquired: 0,
        energy_delegatable: 0,
        bandwidth_staked: 0,
        bandwidth_delegated: 0,
        bandwidth_acquired: 0,
        bandwidth_delegatable: 0,
        resource: {
          energy: { conversion_rate: 0, used: 0, total: 0, recovery_time: 0 },
          bandwidth: {
            conversion_rate: 0,
            used: 0,
            total: 0,
            recovery_time: 0,
          },
          tronpower: { used: 0, total: 0 },
        },
      };

      // Process balance information
      if (balance.balance) {
        account.available = fromSun(balance.balance);
      }

      // Process V2 staking and delegation
      if (balance.account_resource) {
        if (balance.account_resource.delegated_frozenV2_balance_for_energy) {
          account.energy_delegated += fromSun(
            balance.account_resource.delegated_frozenV2_balance_for_energy,
          );
        }
        if (
          balance.account_resource
            .acquired_delegated_frozenV2_balance_for_energy
        ) {
          account.energy_acquired += fromSun(
            balance.account_resource
              .acquired_delegated_frozenV2_balance_for_energy,
          );
        }
      }

      if (balance.delegated_frozenV2_balance_for_bandwidth) {
        account.bandwidth_delegated += fromSun(
          balance.delegated_frozenV2_balance_for_bandwidth,
        );
      }

      if (balance.acquired_delegated_frozenV2_balance_for_bandwidth) {
        account.bandwidth_acquired += fromSun(
          balance.acquired_delegated_frozenV2_balance_for_bandwidth,
        );
      }

      if (balance.frozenV2) {
        for (const frozen of balance.frozenV2) {
          if (frozen.amount) {
            if (frozen.type) {
              account.energy_staked = fromSun(frozen.amount);
            } else {
              account.bandwidth_staked = fromSun(frozen.amount);
            }
          }
        }
      }

      // Calculate total staked and balance
      account.staked =
        account.energy_staked +
        account.energy_delegated +
        account.bandwidth_staked +
        account.bandwidth_delegated;
      account.balance = account.available + account.staked;

      // Process resource information
      if (balance.account_resource?.energy_window_size) {
        account.resource.energy.recovery_time =
          3 * balance.account_resource.energy_window_size;
      }

      if (balance.net_window_size) {
        account.resource.bandwidth.recovery_time = 3 * balance.net_window_size;
      }

      account.resource.energy.conversion_rate =
        resource.TotalEnergyWeight / resource.TotalEnergyLimit;
      account.resource.bandwidth.conversion_rate =
        resource.TotalNetWeight / resource.TotalNetLimit;

      if (resource.freeNetLimit)
        account.resource.bandwidth.total += resource.freeNetLimit;
      if (resource.NetLimit)
        account.resource.bandwidth.total += resource.NetLimit;
      if (resource.freeNetUsed)
        account.resource.bandwidth.used += resource.freeNetUsed;
      if (resource.NetUsed) account.resource.bandwidth.used += resource.NetUsed;
      if (resource.EnergyLimit)
        account.resource.energy.total += resource.EnergyLimit;
      if (resource.EnergyUsed)
        account.resource.energy.used += resource.EnergyUsed;
      if (resource.tronPowerLimit)
        account.resource.tronpower.total += resource.tronPowerLimit;
      if (resource.tronPowerUsed)
        account.resource.tronpower.used += resource.tronPowerUsed;

      // Get delegatable energy
      const energy: TronWebEnergy = await tronWeb.trx.getCanDelegatedMaxSize(
        address,
        "ENERGY",
      );
      if (energy.max_size) {
        account.energy_delegatable = Math.floor(
          fromSun(energy.max_size) / account.resource.energy.conversion_rate,
        );
      }
      const bandwidth: TronWebEnergy = await tronWeb.trx.getCanDelegatedMaxSize(
        address,
        "BANDWIDTH",
      );
      if (bandwidth.max_size) {
        account.bandwidth_delegatable = Math.floor(
          fromSun(bandwidth.max_size) /
            account.resource.bandwidth.conversion_rate,
        );
      }

      console.log("Account", account);
      return account;
    },
    enabled: connected && !!address,
    refetchInterval: 20000, // Refetch every 60 seconds
  });
}

export function useInvalidateTronAccount(): () => void {
  const queryClient = useQueryClient();
  const { address } = useWallet();

  return () => {
    queryClient.invalidateQueries({
      queryKey: [TRON_ACCOUNT_KEY, address],
    });
  };
}
