import type React from "react";
import { useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LightningBoltIcon, RocketIcon } from "@radix-ui/react-icons";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { Gauge, WalletMinimal } from "lucide-react";
import type { DialogProps } from "@/contexts/dialogContext";
import { LayoutDialog } from "./layoutDialog";
import { tronWeb } from "@/lib/tronWeb";
import { useMarketData } from "@/hooks/useMarketData";
import { api } from "@/lib/api";
import { useQuery } from "@tanstack/react-query";
import { toSun, fromSun } from "@/utils/convertCurrency";
import { formatDuration } from "@/utils/formatDuration";
import { useTronAccount } from "@/hooks/useTronWeb";
import { Separator } from "../ui/separator";
import { Button } from "../ui/button";
import { toast } from "sonner";
import { queryClient } from "@/lib/queryClient";
import { env } from "@/env";

interface FillOrderDialogProps extends DialogProps<"fillOrder"> {}

export const FillOrderDialog: React.FC<FillOrderDialogProps> = ({
  isOpen,
  onClose,
  payload,
}) => {
  const { address, signTransaction, connected } = useWallet();
  const { data: accountData } = useTronAccount();
  const { data: marketData, isLoading: marketDataLoading } = useMarketData();

  const {
    data: order,
    isLoading: orderLoading,
    error: orderError,
  } = useQuery({
    queryKey: ["order", payload.id],
    queryFn: async () => {
      const response = await api.orders.fetch.get({
        query: { id: payload.id },
      });
      return response.data;
    },
    enabled: isOpen,
  });

  const formSchema = z.object({
    stakeAmount: z.number(),
    payoutAddress: z.string().length(34, "Address must be 34 characters long"),
  });

  type FormValues = z.infer<typeof formSchema>;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onChange",
    defaultValues: {
      stakeAmount: 0,
      payoutAddress: address || "",
    },
  });

  const stakeAmount = watch("stakeAmount");

  useEffect(() => {
    if (address) {
      setValue("payoutAddress", address);
    }
    if (order && marketData?.order) {
      const minFill =
        order.resourceType === "ENERGY"
          ? marketData.order.minFillEnergy
          : marketData.order.minFillBandwidth;

      setValue(
        "stakeAmount",
        order.settings?.partialFill ? minFill : order.resourceAmount,
      );
    }
  }, [address, order, marketData, setValue]);

  const validateStakeAmount = useCallback(() => {
    if (!order || !marketData?.order || !accountData) return;

    const delegatable =
      order.resourceType === "ENERGY"
        ? accountData.energy_delegatable
        : accountData.bandwidth_delegatable;

    const minFill =
      order.resourceType === "ENERGY"
        ? marketData.order.minFillEnergy
        : marketData.order.minFillBandwidth;

    const remainingAmount =
      order.resourceAmount -
      Math.round(
        fromSun(Number(order.frozen)) /
          (order.resourceType === "ENERGY"
            ? accountData.resource.energy.conversion_rate
            : accountData.resource.bandwidth.conversion_rate),
      );
    const maxFill = Math.max(0, remainingAmount);

    clearErrors("stakeAmount");

    // If the remaining amount is less than minFill, set the only option to the remaining amount
    if (remainingAmount > 0 && remainingAmount < minFill) {
      setValue("stakeAmount", remainingAmount);
      return;
    }

    // Check if stake amount is within the valid range
    if (stakeAmount < minFill || stakeAmount > maxFill) {
      setError("stakeAmount", {
        type: "manual",
        message: `Stake amount must be between ${minFill} and ${maxFill}`,
      });
    }

    // Check if there's sufficient balance
    if (stakeAmount > delegatable) {
      setError("stakeAmount", {
        type: "manual",
        message: `Insufficient balance. Maximum available: ${delegatable}`,
      });
    }
  }, [
    order,
    marketData,
    accountData,
    stakeAmount,
    setError,
    clearErrors,
    setValue,
  ]);

  useEffect(() => {
    validateStakeAmount();
  }, [validateStakeAmount]);

  const calculateTrxAmount = useCallback(
    (amount: number) => {
      if (!order || !accountData) return 0;
      const conversionRate =
        order.resourceType === "ENERGY"
          ? accountData?.resource.energy.conversion_rate
          : accountData?.resource.bandwidth.conversion_rate;
      return Math.ceil(amount * conversionRate);
    },
    [order, accountData],
  );

  const calculateReward = useCallback(
    (partialAmount: number) => {
      if (!order?.resourceAmount || !marketData?.order?.fillOrderAward) {
        console.warn("Missing data for reward calculation:", {
          order,
          marketData,
        });
        return 0;
      }

      const fraction = partialAmount / order.resourceAmount;
      const reward =
        Number(order.payment) * fraction * marketData.order.fillOrderAward;

      return fromSun(reward);
    },
    [order, marketData],
  );

  const onSubmit = handleSubmit(async (data) => {
    if (!order || !address || !connected) return;

    try {
      const tx = await tronWeb.transactionBuilder.delegateResource(
        // toSun(calculateTrxAmount(data.stakeAmount)),
        env.VITE_TEST_ENV
          ? toSun(calculateTrxAmount(data.stakeAmount)) / 100
          : toSun(calculateTrxAmount(data.stakeAmount)),
        order.targetAddress,
        order.resourceType,
        address,
        true,
        order.duration / 3,
      );

      const signedTx = await signTransaction(tx);
      const result = await tronWeb.trx.sendRawTransaction(signedTx);

      if (result.result) {
        const res = await api.orders.fulfill.post(
          {
            orderId: order.id,
            tx: signedTx.txID,
            signature: signedTx.signature,
            payoutAddress: data.payoutAddress,
          },
          {
            headers: {
              "x-wallet": address,
            },
          },
        );
        if (res.data) {
          toast.success("Order filled successfully");
          queryClient.invalidateQueries({ queryKey: ["orders"] });
        }
        onClose();
      } else {
        toast.error("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (error) {
      toast.error("Error filling order");
      console.error("Error filling order:", error);
    }
  });

  if (marketDataLoading || orderLoading || !accountData) return <div />;
  if (!marketData?.order) return <div>Error loading market data</div>;
  if (orderError || !order) return <div>Error loading order details</div>;

  const minFill =
    order.resourceType === "ENERGY"
      ? marketData.order.minFillEnergy
      : marketData.order.minFillBandwidth;

  const maxFill = Math.max(
    order.resourceAmount -
      Math.round(
        fromSun(Number(order.frozen)) /
          (order.resourceType === "ENERGY"
            ? accountData.resource.energy.conversion_rate
            : accountData.resource.bandwidth.conversion_rate),
      ),
    0,
  );

  return (
    <LayoutDialog
      isOpen={isOpen}
      onClose={onClose}
      icon={<RocketIcon className="h-8 w-8 text-primary-foreground" />}
      title="Fill the order"
      onConfirm={onSubmit}
      confirmText={isSubmitting ? "Filling..." : "Fill Order"}
      confirmDisabled={!!errors.stakeAmount || isSubmitting}
    >
      <form onSubmit={onSubmit} className="grid gap-4 py-4">
        <div className="space-y-2">
          <Label htmlFor="stakeAmount" className="flex items-center gap-1">
            To delegate amount ({order.resourceType})
          </Label>
          <div className="flex items-center ">
            <Controller
              name="stakeAmount"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="stakeAmount"
                  type="number"
                  min={Math.min(minFill, maxFill)}
                  max={maxFill}
                  className={`${errors.stakeAmount ? "border-red-500" : ""}${
                    order.settings?.partialFill || maxFill < minFill
                      ? " rounded-r-none"
                      : ""
                  }`}
                  disabled={!order.settings?.partialFill || maxFill < minFill}
                  onChange={(e) => field.onChange(Number(e.target.value))}
                />
              )}
            />
            {order.settings?.partialFill && maxFill >= minFill && (
              <Button
                type="button"
                onClick={() => setValue("stakeAmount", maxFill)}
                className="rounded-l-none text-xs"
              >
                Max
              </Button>
            )}
          </div>
          {errors.stakeAmount && (
            <p className="text-red-500 text-sm">{errors.stakeAmount.message}</p>
          )}
          {order.settings?.partialFill && maxFill >= minFill && (
            <p className="text-gray-500 text-xs">
              Min: {Math.min(minFill, maxFill).toLocaleString()}, Max:{" "}
              {maxFill.toLocaleString()}
            </p>
          )}
        </div>
        <div className="space-y-2">
          <Label htmlFor="payoutAddress" className="flex items-center gap-1">
            Payout target address
          </Label>
          <Controller
            name="payoutAddress"
            control={control}
            render={({ field }) => (
              <div className="relative w-full">
                <Input {...field} id="payoutAddress" className="pr-10" />
                <WalletMinimal className="absolute top-2.5 right-2 h-5 w-5 text-muted-foreground" />
              </div>
            )}
          />
          {errors.payoutAddress && (
            <p className="text-red-500 text-sm">
              {errors.payoutAddress.message}
            </p>
          )}
        </div>
        <OrderSummary
          resourceType={order.resourceType}
          stakeAmount={stakeAmount}
          duration={order.duration}
          calculateReward={calculateReward}
          calculateTrxAmount={calculateTrxAmount}
        />
      </form>
    </LayoutDialog>
  );
};

interface OrderSummaryProps {
  resourceType: string;
  stakeAmount: number;
  duration: number;
  calculateReward: (amount: number) => number;
  calculateTrxAmount: (amount: number) => number;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  resourceType,
  stakeAmount,
  duration,
  calculateReward,
  calculateTrxAmount,
}) => (
  <div className="space-y-2">
    {resourceType === "ENERGY" ? (
      <SummaryRow
        label={"Energy"}
        value={`${stakeAmount.toLocaleString()} `}
        highlight={true}
        icon={<LightningBoltIcon className="h-4 w-4" />}
      />
    ) : (
      <SummaryRow
        label={"Bandwidth"}
        value={`${stakeAmount.toLocaleString()} `}
        highlight={true}
        icon={<Gauge className="h-4 w-4" />}
      />
    )}

    <SummaryRow label="Duration" value={formatDuration(duration)} />

    <Separator />
    <SummaryRow
      label="Total payout"
      value={`+${calculateReward(stakeAmount).toLocaleString()} (TRX)`}
      highlight
    />
    <SummaryRow
      label="TRX to delegate"
      value={`${calculateTrxAmount(stakeAmount).toLocaleString()} TRX`}
      highlight
    />
  </div>
);

interface SummaryRowProps {
  label: string;
  value: string;
  icon?: React.ReactNode;
  highlight?: boolean;
}

const SummaryRow: React.FC<SummaryRowProps> = ({
  label,
  value,
  icon,
  highlight,
}) => (
  <div className="flex justify-between">
    <span className="flex items-center gap-1">{label}</span>
    <span
      className={
        highlight
          ? "flex items-center gap-1 text-primary"
          : "flex items-center gap-1"
      }
    >
      {value}
      {icon}
    </span>
  </div>
);

// const FillOrderDialogSkeleton: React.FC<{
//   isOpen: boolean;
//   onClose: () => void;
// }> = ({ isOpen, onClose }) => {
//   return (
//     <LayoutDialog
//       isOpen={isOpen}
//       onClose={onClose}
//       icon={<RocketIcon className="h-8 w-8 text-primary-foreground" />}
//       title="Fill the order"
//       onConfirm={() => {}}
//       confirmText="Fill Order"
//       confirmDisabled={true}
//     >
//       <div className="grid gap-4 py-4">
//         <div className="space-y-2">
//           <Skeleton className="h-4 w-1/4" />
//           <Skeleton className="h-10 w-full" />
//         </div>
//         <div className="space-y-2">
//           <Skeleton className="h-4 w-1/3" />
//           <Skeleton className="h-10 w-full" />
//         </div>
//         <div className="space-y-2">
//           <Skeleton className="h-4 w-full" />
//           <Skeleton className="h-4 w-full" />
//           <Skeleton className="h-4 w-full" />
//           <Skeleton className="h-4 w-full" />
//           <Skeleton className="h-4 w-full" />
//         </div>
//       </div>
//     </LayoutDialog>
//   );
// };
